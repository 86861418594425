import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "my-4 space-x-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "grid grid-cols-10 2xl:gap-4 gap-2" }
const _hoisted_7 = { class: "col-span-4" }
const _hoisted_8 = { class: "col-span-6 relative" }
const _hoisted_9 = {
  key: 0,
  label: "Scope 1",
  name: "1",
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_10 = { class: "py-4 relative w-full" }
const _hoisted_11 = {
  key: 1,
  label: "Scope 1",
  name: "1",
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_12 = { class: "py-4 relative w-full" }
const _hoisted_13 = {
  key: 2,
  label: "Scope 1",
  name: "1",
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_14 = { class: "py-4 relative w-full" }
const _hoisted_15 = {
  key: 0,
  class: "box grid grid-cols-10 2xl:gap-4 gap-2 mt-2"
}
const _hoisted_16 = { class: "col-span-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/scope-2-renewables-performance" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Scope 2 Renewable Performance ")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "input-data-manual" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Input Scope 2")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH_SCOPE2_INPUT_MANUAL), (value) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "inline-block",
          key: value
        }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(['tab-box', $setup.route.hash === `#${value}` && 'active']),
            onClick: _withModifiers(($event: any) => ($setup.handleTabClick(value)), ["prevent"])
          }, _toDisplayString($setup.computeTabName($setup.TAB_SCOPE2_INPUT_MANUAL, value)), 11, _hoisted_5)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass([
            'box py-3 relative',
            ($setup.isLoadingEnergyLoadDetail ||
              $setup.isLoadingGeneratedEnergyDetail ||
              $setup.isLoadingRenewablesEnergyDetail) &&
              'isSubmitting-rounded',
          ])
        }, [
          (
              $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.ENERGY_CONSUMPTION}`
            )
            ? (_openBlock(), _createBlock($setup["TheEnergyLoadsForm"], {
                key: 0,
                onResetFormEnergyLoadsOnClickRow: $setup.resetFormOnClickRowEnergyLoads
              }))
            : (
              $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.RENEWABLES_ENERGY}`
            )
              ? (_openBlock(), _createBlock($setup["TheRenewableEnergyTable"], { key: 1 }))
              : (_openBlock(), _createBlock($setup["TheGeneratedEnergyTable"], { key: 2 }))
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (
            $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.ENERGY_CONSUMPTION}`
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mt-5" }, " Grid Energy Consumption (kWh) ", -1)),
              _createElementVNode("div", {
                class: _normalizeClass([
              'h-full',
              $setup.isLoadingEnergyLoads ? 'isSubmitting-rounded' : '',
            ])
              }, [
                _createElementVNode("div", _hoisted_10, [
                  (!$setup.isLoadingEnergyLoads)
                    ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                        key: 0,
                        class: _normalizeClass(_ctx.styles['column-size']),
                        data: $setup.dataScopeTwoEnergyLoad,
                        colors: ['#808080', '#808080'],
                        seriesName: "Grid Energy Consumption (kWh)",
                        yTitle: "kWh",
                        legendStyle: {
                  position: 'bottom',
                  fontSize: 14,
                  padding: [15, 15, 0, 0],
                },
                        withExtraYScale: false,
                        lineAllowExtraYScale: true,
                        "show-labels": true
                      }, null, 8, ["class", "data"]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ]))
          : (
            $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.RENEWABLES_ENERGY}`
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mt-5" }, " Renewables (kWh) ", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'h-full  ',
              $setup.isLoadingRenewablesEnergy ? 'isSubmitting-rounded' : '',
            ])
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    (!$setup.isLoadingRetireRecs)
                      ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                          key: 0,
                          class: _normalizeClass(_ctx.styles['column-size']),
                          data: $setup.getRenewablesEnergyChart,
                          data_2: $setup.getRetireRECs,
                          colors: ['#8EC63F', '#8EC63F'],
                          colors_2: ['#17a46d', '#17a46d'],
                          seriesName: "External REC (kWh)",
                          seriesName_2: "TRECs REC (kWh)",
                          yTitle: "kWh",
                          legendStyle: {
                  position: 'bottom',
                  fontSize: 14,
                  padding: [15, 15, 0, 0],
                },
                          withExtraYScale: false,
                          lineAllowExtraYScale: true,
                          "show-labels": true
                        }, null, 8, ["class", "data", "data_2"]))
                      : _createCommentVNode("", true)
                  ])
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mt-5" }, " Onsite PV Generation (kWh) ", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'h-full',
              $setup.isLoadingEnergyLoads ? 'isSubmitting-rounded' : '',
            ])
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    (!$setup.isLoadingGeneratedEnergyTotal)
                      ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                          key: 0,
                          class: _normalizeClass(_ctx.styles['column-size']),
                          data: $setup.dataGeneratedEnergy,
                          colors: ['#ED7D31', '#ED7D31'],
                          seriesName: "Onsite PV Generation (kWh)",
                          yTitle: "kWh",
                          legendStyle: {
                  position: 'bottom',
                  fontSize: 14,
                  padding: [15, 15, 0, 0],
                },
                          withExtraYScale: false,
                          lineAllowExtraYScale: true,
                          "show-labels": true
                        }, null, 8, ["class", "data"]))
                      : _createCommentVNode("", true)
                  ])
                ], 2)
              ]))
      ])
    ]),
    ($setup.route.hash !== `#${$setup.HASH_SCOPE2_INPUT_MANUAL.ENERGY_CONSUMPTION}`)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", {
              class: _normalizeClass([
            ' py-3 relative',
            ($setup.isLoadingEnergyLoadDetail ||
              $setup.isLoadingGeneratedEnergyDetail ||
              $setup.isLoadingRenewablesEnergyDetail) &&
              'isSubmitting-rounded',
          ])
            }, [
              (
              $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.RENEWABLES_ENERGY}`
            )
                ? (_openBlock(), _createBlock($setup["TheRenewablesEnergyForm"], {
                    key: 0,
                    onResetFormEnergyLoadsOnClickRow: 
              $setup.resetFormOnClickRowRenewablesEnergy
            
                  }, null, 8, ["onResetFormEnergyLoadsOnClickRow"]))
                : (
              $setup.route.hash === `#${$setup.HASH_SCOPE2_INPUT_MANUAL.SOLAR_PV_GENERATION}`
            )
                  ? (_openBlock(), _createBlock($setup["TheGeneratedEnergyForm"], { key: 1 }))
                  : _createCommentVNode("", true)
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
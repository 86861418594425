import { defineComponent as _defineComponent } from 'vue'
import { IScopeTwoRequestDetailParamsDTO } from "@/models/ScopeTwoEmissions";
import { computeTabName, useHash } from "@/utils/hooks";
import {
  HASH_SCOPE2_INPUT_MANUAL,
  TAB_SCOPE2_INPUT_MANUAL,
} from "@ems/constants";
import TheEnergyLoadsForm from "@ems/containers/Dashboard/TheForm/Energy_Loads.Form.vue";
import TheRenewablesEnergyForm from "@ems/containers/Dashboard/TheForm/Renewables_Energy.Form.vue";
import TheGeneratedEnergyForm from "@ems/containers/Dashboard/TheForm/Generated_Energy.Form.vue";
import TheGeneratedEnergyTable from "@ems/containers/ScopeTwoEmission/Table/Generated_Energy.Table.vue";
import TheRenewableEnergyTable from "@ems/containers/ScopeTwoEmission/Table/Renewable_Energy.Table.vue";
import S2RPModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import RetireRECsModule from "@ems/containers/Dashboard/TheForm/RetireRECs.module";
import isEmpty from "lodash/isEmpty";
import { computed, ref } from "vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import { useRoute, useRouter } from "vue-router";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import S2GeneratedEnergyModule from "@ems/containers/ScopeTwoEmission/Table/S2GeneratedEnergy.module";

export default /*@__PURE__*/_defineComponent({
  __name: 'InputDataManual',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const energyLoadsForm = ref();
const generatedEnergyForm = ref();
const renewablesEnergyForm = ref();

const dataScopeTwoRenewableEnergy = computed(
  () => S2RPModule.renewablesEnergyChartGetter
);
const dataScopeTwoEnergyLoad = computed(
  () => S2RPModule.energyLoadsChartGetter
);
const dataGeneratedEnergy = computed(
  () => S2GeneratedEnergyModule.dataScopeTwoEnergyLoadGetter
);
const isLoadingGeneratedEnergyTotal = computed(
  () => S2GeneratedEnergyModule.isLoadingScopeTwoEmissionGetter
);
const isLoadingEnergyLoads = computed(
  () => S2RPModule.isLoadingEnergyLoadsTotalGetter
);
const isLoadingRenewablesEnergy = computed(
  () => S2RPModule.isLoadingRenewablesEnergyTotalGetter
);

const isLoadingEnergyLoadDetail = computed(
  () => S2RPModule.isLoadingEnergyLoadsRequestDetailGetter
);
const isLoadingGeneratedEnergyDetail = computed(
  () => S2RPModule.isLoadingGeneratedEnergyRequestDetailGetter
);
const isLoadingRenewablesEnergyDetail = computed(
  () => S2RPModule.isLoadingRenewablesEnergyRequestDetailGetter
);

const isLoadingRetireRecs = computed(
  () => RetireRECsModule.isLoadingGetRetireRECsGetter
);

const getRetireRECs = computed(() => RetireRECsModule.getRetireRECsGetter);

const getRenewablesEnergyChart = computed(
  () => RetireRECsModule.getRenewablesEnergyChartGetter
);

useHash(HASH_SCOPE2_INPUT_MANUAL);
const handleTabClick = (tabs: string) => {
  router.replace({ hash: `#${tabs}` });
};
const handleRowClickEnergyLoad = async (row: any) => {
  handleResetEnergyLoadsForm();
  S2RPModule.toggleEnergyLoadsButtonType(true);
  const data = {
    Year: S2RPModule.currentOptionYearEnergyLoadGetter,
    requestId: row.Id,
  };
  await S2RPModule.getEnergyLoadRequestDetailAction(
    data as IScopeTwoRequestDetailParamsDTO
  );
};
const handleResetEnergyLoadsForm = () => {
  !isEmpty(energyLoadsForm.value) &&
    energyLoadsForm.value.resetForm({
      values: {},
    });
};
const handleRowClickGeneratedEnergy = async (row: any) => {
  handleResetGeneratedEnergyForm();
  S2RPModule.toggleGeneratedEnergyButtonType(true);
  const data = {
    Year: S2RPModule.currentOptionYearGeneratedGetter,
    requestId: row.Id,
  };
  await S2RPModule.getGeneratedEnergyRequestDetailAction(
    data as IScopeTwoRequestDetailParamsDTO
  );
};
const handleResetGeneratedEnergyForm = () => {
  !isEmpty(generatedEnergyForm.value) &&
    generatedEnergyForm.value.resetForm({
      values: {},
    });
};

const handleRowClickRenewablesEnergy = async (row: any) => {
  handleResetRenewablesEnergyForm();
  S2RPModule.toggleRenewablesEnergyButtonType(true);
  const data = {
    Year: S2RPModule.currentOptionYearRenewablesGetter,
    requestId: row.Id,
  };
  await S2RPModule.getRenewablesEnergyRequestDetailAction(
    data as IScopeTwoRequestDetailParamsDTO
  );
};

const handleResetRenewablesEnergyForm = () => {
  !isEmpty(generatedEnergyForm.value) &&
    generatedEnergyForm.value.resetForm({
      values: {},
    });
};

const resetFormOnClickRowEnergyLoads = (form: any) => {
  energyLoadsForm.value = form;
  return;
};
const resetFormOnClickRowGeneratedEnergy = (form: any) => {
  generatedEnergyForm.value = form;
  return;
};

const resetFormOnClickRowRenewablesEnergy = (form: any) => {
  renewablesEnergyForm.value = form;
  return;
};

const dataScopeTwoLineMarker = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoLineMarkerGetter
);

const __returned__ = { route, router, energyLoadsForm, generatedEnergyForm, renewablesEnergyForm, dataScopeTwoRenewableEnergy, dataScopeTwoEnergyLoad, dataGeneratedEnergy, isLoadingGeneratedEnergyTotal, isLoadingEnergyLoads, isLoadingRenewablesEnergy, isLoadingEnergyLoadDetail, isLoadingGeneratedEnergyDetail, isLoadingRenewablesEnergyDetail, isLoadingRetireRecs, getRetireRECs, getRenewablesEnergyChart, handleTabClick, handleRowClickEnergyLoad, handleResetEnergyLoadsForm, handleRowClickGeneratedEnergy, handleResetGeneratedEnergyForm, handleRowClickRenewablesEnergy, handleResetRenewablesEnergyForm, resetFormOnClickRowEnergyLoads, resetFormOnClickRowGeneratedEnergy, resetFormOnClickRowRenewablesEnergy, dataScopeTwoLineMarker, get IScopeTwoRequestDetailParamsDTO() { return IScopeTwoRequestDetailParamsDTO }, get computeTabName() { return computeTabName }, get useHash() { return useHash }, get HASH_SCOPE2_INPUT_MANUAL() { return HASH_SCOPE2_INPUT_MANUAL }, get TAB_SCOPE2_INPUT_MANUAL() { return TAB_SCOPE2_INPUT_MANUAL }, TheEnergyLoadsForm, TheRenewablesEnergyForm, TheGeneratedEnergyForm, TheGeneratedEnergyTable, TheRenewableEnergyTable, get S2RPModule() { return S2RPModule }, get RetireRECsModule() { return RetireRECsModule }, get isEmpty() { return isEmpty }, computed, ref, ColumnChart, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get S2GeneratedEnergyModule() { return S2GeneratedEnergyModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})